<template>
  <div class="container">
    <iframe
      ref="iframeModel"
      src="http://kjbx.dianlan.work/aichuangzuo/index.html"
      style="width: 100%; height: 100%"
      frameborder="0"
    > </iframe>
  </div>
</template>
<script>
import { ipcApiRoute, specialIpcRoute } from "@/api/main";
export default {
  data () {
    return {
      active: "1",
      liveId: null,
      showList: true,
    };
  },
  mounted () {
    // this.init();
  },
  methods: {
    init () {
      this.$ipc.invoke(ipcApiRoute.openSoftware, "app.exe").then((r) => {
        console.log("resourcePath:", r);
      });
    },
    select (e) {
      this.active = e;
    },
    add (id) {
      this.liveId = id + "";
      this.showList = !this.showList;
      if (this.showList) {
        console.log(123);
        this.$refs.liveList.getlive();
      }
    },
  },
};
</script>
<style scoped>
v-deep.is-active {
  color: #fff;
  background: #409eff;
}
</style>
